<template>
  <div class="Diplomados">
    <section class="pt96 pb0">
      <div class="container">
        <div class="row mb96 mb-xs-24">
          <div class="col-sm-12 text-center">
            <h1 class="fade-1-4 uppercase">DIPLOMADO BIOemoción Inteligente</h1>
            <p class="mb0">
              Una formación terapéutica de alto nivel consiste en llevar al
              alumno a la adquisición de conocimiento teórico, a una nueva toma
              de consciencia personal, a ser generoso para generar abundancia, a
              conocer un nuevo estilo de vida basado en el equilibro del Estado
              del Ser. “El terapeuta emocional reproduce lo que él es en su
              acompañamiento de sanación del otro”.
            </p>
          </div>
        </div>
        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <section
      class="pb0"
      data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-sm-6 col-md-4"
            v-for="(dpl,idx) in diplomados"
            :key="idx"
          >
            <router-link
              :to="'/diplomado/'+dpl.id+'/'+dpl.url_dip"
              class="fade-on-hover"
            >
              <img
                v-if="dpl.imagen_dip"
                class="mb24"
                alt="Pic"
                :src="$baseUrl+dpl.imagen_dip"
              />
              <img
                v-else
                class="mb24"
                alt="Pic"
                src="@/assets/gradientes/41TonysPink.jpg"
              />
            </router-link>
            <h4
              class="uppercase"
              v-text="dpl.nombre_dip"
            />
            <h6
              class="uppercase"
              v-text="'— '+dpl.tipo_dip"
            />
            <p v-html="dpl.descripcion_dip" />
            <router-link
              class="btn btn-filled"
              :to="'/diplomado/'+dpl.id+'/'+dpl.url_dip"
              v-text="'Leer más'"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="pb0">
      <conferencias-proximas />
      <div class="pt240 pb240 relative">
        <div class="background-image-holder">
          <img
            alt="Pic"
            class="background-image background-image-holder"
            src="@/assets/gradientes/FairyFloss.jpg"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import ConferenciasProximas from "@/components/conferencias/ConferenciasProximas.vue";
export default {
  name: "Diplomados",
  mixins: [forms],
  components: { ConferenciasProximas },
  data() {
    return {
      diplomados: [],
    };
  },
  created() {
    this._getThings("diplomados", "diplomados");
    console.log("diplomados get");
  },
};
</script>